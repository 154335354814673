(<template>
  <article class="dashboard">
    <feedback-banner v-if="feedbackJobs.length > 0" />
    <service-banner v-if="showLanguageAssistantBanner" />
    <!-- <hotline-availability v-if="showHotlineAvailability"
                          class="dashboard__hot-availability-section dashboard__hot-availability-section--mobile" /> -->
    <section class="dashboard__widgets-section">
      <div class="dashboard__assignment-sections-wrapper">
        <section class="dashboard__assignments-sections-cont dashboard__assignments-sectioncont--confirmed">
          <section :class="{'in-progress': bookedAssignmentsProgress || searchAssignmentsProgress}"
                   class="dashboard__assignments-section is-progress-bar">
            <assignment-search>
              <header v-if="!searchQuery"
                      class="dashboard__section-header">
                <h2 class="dashboard__section-title">{{ $gettext('Confirmed assignments') }}</h2>
                <router-link :to="assignmentsLink"
                             class="dashboard__section-link">{{ viewAllBookedText }}</router-link>
              </header>
              <template v-if="isBookedAssigns || searchQuery">
                <assignments-list :assignments-list="myAssignmentsData.jobs"
                                  :show-all-btn="{display: bookedAssignmentsPages > 1 && !searchQuery}"
                                  class="dashboard__assignment-list" />
              </template>
              <template v-else>
                <empty-state :text="noBookedAssignsText" />
              </template>
            </assignment-search>
          </section>
        </section>
        <section class="dashboard__assignments-sections-cont dashboard__assignments-section-cont--available">
          <section class="dashboard__assignments-section is-progress-bar"
                   :class="{'in-progress': availableAssignmentsProgress}">
            <header class="dashboard__section-header">
              <h2 class="dashboard__section-title">{{ $gettext('Available assignments') }}</h2>
              <router-link :to="assignmentsLink"
                           class="dashboard__section-link">{{ viewAllAvailableText }}</router-link>
            </header>
            <template v-if="isAvailableAssigns">
              <assignments-list :assignments-list="availableAssignments"
                                :show-all-btn="{display: availableAssignmentsPages > 1}"
                                :show-offer="true"
                                class="dashboard__assignment-list" />
            </template>
            <template v-else>
              <empty-state :text="noAvailableAssignsText" />
            </template>
          </section>
        </section>
      </div>
      <div class="dashboard__calendar-section-wrapper">
        <!-- <hotline-availability class="dashboard__hot-availability-section" /> -->
        <!-- <mob-app-promotion v-if="isNotMobileApp"
                           class="dashboard__promotion-section" /> -->
        <assignment-withdraw-rules class="dashboard__withdraw-section" />
        <stand-by v-if="userCanStandBy"
                  class="dashboard__stand-by-section" />
        <section v-if="!isNotMobileApp"
                 :class="{'in-progress': calendarProgress}"
                 class="dashboard__calendar-section is-progress-bar">
          <header class="dashboard__section-header">
            <div class="dashboard__switchers-cont">
              <button :class="{'dashboard__switcher--active': !tomorrowCalendar}"
                      class="dashboard__switcher"
                      @click="toggleCalendar">{{ $gettext('Today') }}</button>
              <span class="dashboard__switchers-devider"></span>
              <button :class="{'dashboard__switcher--active': tomorrowCalendar}"
                      class="dashboard__switcher"
                      @click="toggleCalendar">{{ $gettext('Tomorrow') }}</button>
            </div>
            <router-link :to="calendarLink"
                         class="dashboard__section-link">{{ $gettext('View calendar') }}</router-link>
          </header>
          <div class="dashboard__calendar-wrapper">
            <full-calendar :active-date="dateForCalendar"
                           :view="'timeGridDay'"
                           :events="tomorrowCalendar ? calendarJobsTomorrow : calendarJobs"
                           :column-header="false"
                           @startprogress="calendarProgress = true"
                           @stopprogress="calendarProgress = false" />
          </div>
        </section>
      </div>
    </section>
  </article>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';
  // import HotlineAvailability from '@/components/hotline/HotlineAvailability';
  import AssignmentsList from '@/components/assignment_components/dashboard/AssignmentsList';
  import EmptyState from '@/components/dashboard_components/EmptyState';
  import FullCalendar from '@/components/calendars/FullCalendar';
  import FeedbackBanner from '@/components/shared_components/banners/FeedbackBanner';
  import AssignmentSearch from '@/components/assignment_components/dashboard/AssignmentSearch';
  import StandBy from '@/components/dashboard_components/stand-by_events/StandByEvents';
  // import MobAppPromotion from '@/components/dashboard_components/MobAppPromotion';
  import AssignmentWithdrawRules from '@/components/dashboard_components/AssignmentWithdrawRules';
  import ServiceBanner from '@/components/shared_components/banners/ServiceBanner';

  export default {
    asyncData({store}) {
      const userUid = store.getters['UserInfoStore/userUid'];
      let promiseArr = '';
      if (!store.state.EditProfileStore.profileData && userUid) {
        promiseArr = [...promiseArr, store.dispatch('EditProfileStore/getUserProfile', {id: userUid})];
      }
      if (!store.state.TTSignaturesStore.documentsForSigning) {
        promiseArr = [...promiseArr, store.dispatch('TTSignaturesStore/getDocumentsForSigning')];
      }
      if (!store.state.s_DashboardStore.feedbackJobs) {
        promiseArr = [...promiseArr, store.dispatch('s_DashboardStore/getFeedbackJobs')];
      }

      if (!store.state.TTPrivateEventsStore.privateEvents && store.getters['UserInfoStore/userCanStandBy']) {
        promiseArr = [...promiseArr, store.dispatch('TTPrivateEventsStore/fetchPrivateEvents')];
      }

      if (store.state.DocumentCenterStore.allDocuments.documents && !store.state.DocumentCenterStore.allDocuments.documents.length) {
        promiseArr = [...promiseArr, store.dispatch('DocumentCenterStore/getDocuments')];
      }

      return promiseArr ? Promise.all(promiseArr) : promiseArr;
    },
    components: {
      'assignment-withdraw-rules': AssignmentWithdrawRules,
      // 'mob-app-promotion': MobAppPromotion,
      'stand-by': StandBy,
      'feedback-banner': FeedbackBanner,
      'full-calendar': FullCalendar,
      // 'hotline-availability': HotlineAvailability,
      'assignments-list': AssignmentsList,
      'empty-state': EmptyState,
      'assignment-search': AssignmentSearch,
      'service-banner': ServiceBanner
    },
    data() {
      return {
        clientWidth: 768,
        intervalId: '',
        calendarProgress: true,
        availableAssignmentsProgress: false,
        bookedAssignmentsProgress: false,
        intervalCanUpdate: true,
        tomorrowCalendar: false
      };
    },
    computed: {
      ...mapState({
        isNotMobileApp: ({mobileApp}) => !mobileApp
      }),
      ...mapState('UserInfoStore', {
        privacyPopup: ({additionalUserInfo}) => additionalUserInfo.privacyPopup,
        // allowForHotline: ({additionalUserInfo}) => additionalUserInfo.allowForHotline,
        cooperationAgreementSigned: ({additionalUserInfo}) => additionalUserInfo.cooperationAgreementSigned
      }),
      ...mapState('EditProfileStore', {
        profileData: (state) => state.profileData
      }),
      ...mapState('s_DashboardStore', {
        calendarJobs: (state) => state.todayJobs || {},
        calendarJobsTomorrow: (state) => state.tomorrowJobs || {},
        bookedAssignmentsData: (state) => state.bookedAssignments.data || {},
        availableAssignmentsData: (state) => state.availableAssignments.data || {},
        feedbackJobs: (state) => state.feedbackJobs || []
      }),
      ...mapState('DashboardSearchStore', {
        searchAssignmentsData: ({searchAssignments}) => searchAssignments || {},
        searchQuery: ({searchQuery}) => searchQuery,
        searchAssignmentsProgress: ({progress}) => progress
      }),
      ...mapState('TTSignaturesStore', {
        documentsForSigning: (state) => state.documentsForSigning
      }),
      ...mapGetters('EditProfileStore', ['getPersonInfoModalData']),
      ...mapGetters('UserInfoStore', ['userIsOrgInterpreter', 'userCanStandBy']),
      myAssignmentsData() {
        return this.searchQuery
          ? this.searchAssignmentsData
          : this.bookedAssignmentsData;
      },
      bookedAssignments() { return this.bookedAssignmentsData.jobs || []; },
      bookedAssignmentsPages() { return this.bookedAssignmentsData.pages || 0; },
      bookedAssignmentsAmount() {
        const confirmedAssignments = this.bookedAssignmentsData.jobs.filter((assignment) => assignment.status !== 'cancelled');
        return confirmedAssignments.length || 0;
      },
      availableAssignments() { return this.availableAssignmentsData.jobs || []; },
      availableAssignmentsPages() { return this.availableAssignmentsData.pages || 0; },
      availableAssignmentsAmount() { return this.availableAssignmentsData.count || 0; },
      languageAssistant() { return this.profileData.languageAssistant || ''; },
      needUserInfoUpdate() {
        const data = this.getPersonInfoModalData;
        return this.profileData ? !data.gender || !data.postcode || !data.country : false;
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'ServerCalendar',
          query: {
            view: 'week',
            date: this.tomorrowCalendar ? this.$moment().add(1, 'day').format('YYYY-MM-DD') : this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'ServerAllAssignments',
          query: {
            view: 'current',
            sortConfirmedBy: 1,
            sortAvailableBy: 1,
            pageConfirmedBy: 1,
            pageAvailableBy: 1
          }
        });
      },
      viewAllAvailableText() {
        const text = this.$gettext('View all');
        return this.isAvailableAssigns ? `${text} (${this.availableAssignmentsAmount})` : text;
      },
      viewAllBookedText() {
        const text = this.$gettext('View all');
        return this.isBookedAssigns ? `${text} (${this.bookedAssignmentsAmount})` : text;
      },
      // showHotlineAvailability() {
      //   return typeof this.allowForHotline == 'boolean';
      // },
      showLanguageAssistantBanner() {
        // Has to be hidden for a while
        return false;
        // return this.languageAssistant == '' || this.languageAssistant == 'waiting_for_answer';
      },
      isBookedAssigns() {
        return this.bookedAssignments.length;
      },
      isAvailableAssigns() {
        return this.availableAssignments.length;
      },
      dateForCalendar() {
        const todayDate = this.$moment().format('YYYY-MM-DD');
        const tomorrowDate = this.$moment().add(1, 'day').format('YYYY-MM-DD');
        return this.tomorrowCalendar ? tomorrowDate : todayDate;
      },
      noBookedAssignsText() {
        return this.$gettext('There are no assignments confirmed right now');
      },
      noAvailableAssignsText() {
        return this.$gettext('There are no assignments available right now');
      },
      agreementDoc() {
        if (this.documentsForSigning && this.documentsForSigning.length) return this.documentsForSigning.find((file) => file.type === 'salita_agreement');
        return '';
      },
      showDialectsModal() {
        return JSON.parse(localStorage.getItem('skillsNeedDialect'));
      }
    },
    watch: {
      profileData() {
        this.openPersonInfoModal();
      },
      agreementDoc() {
        this.showCooperationAgreementModal();
      }
    },
    methods: {
      toggleCalendar() {
        let promiseArr = [];

        this.calendarProgress = true;
        if (!this.$store.state.s_DashboardStore.todayJobs) {
          const params = {view: 'day', date: this.$moment().format('YYYY-MM-DD')};
          promiseArr = [...promiseArr, this.$store.dispatch('s_DashboardStore/s_getTodayCalendarJobs', params)];
        }
        if (!this.$store.state.s_DashboardStore.tomorrowJobs) {
          const params = {view: 'day', date: this.$moment(this.$moment().add(1, 'day')).format('YYYY-MM-DD')};
          promiseArr = [...promiseArr, this.$store.dispatch('s_DashboardStore/s_getTomorrowCalendarJobs', params)];
        }
        Promise.all(promiseArr).then(() => {
          this.calendarProgress = false;
          this.tomorrowCalendar = !this.tomorrowCalendar;
        });
      },
      getJobs() {
        if (!this.calendarJobs || !this.calendarJobs.length) {
          const params = {view: 'day', date: this.$moment().format('YYYY-MM-DD')};

          this.calendarProgress = true;
          this.$store.dispatch('s_DashboardStore/s_getTodayCalendarJobs', params).then(() => {
            this.calendarProgress = false;
          });
        }
      },
      openPersonInfoModal() {
        if (this.profileData && this.needUserInfoUpdate && this.cooperationAgreementSigned) {
          this.$store.commit('ModalStore/setModal', {
            component: 'person-info',
            width: 400,
            disableClose: true,
            classes: ['dashboard-pers-info'],
            data: {
              title: '',
              context: this,
              dashboard: true,
              requiredOnly: true,
              data: this.getPersonInfoModalData
            }
          });
        }
      },
      setClientWidth() {
        this.clientWidth = document.documentElement.clientWidth;
      },
      updateDashboardAssignments() {
        return Promise.all([
          this.$store.dispatch('s_DashboardStore/getBookedAssignmentsDashboard'),
          this.$store.dispatch('s_DashboardStore/getAvailableAssignmentsDashboard')
        ]).then(() => {
          this.intervalCanUpdate = false;
        });
      },
      fetchAvailableBookedAssignments() {
        this.availableAssignmentsProgress = true;
        this.bookedAssignmentsProgress = true;
        return Promise.all([
          this.$store.dispatch('s_DashboardStore/getBookedAssignmentsDashboard', {items: 10, page: 1}),
          this.$store.dispatch('s_DashboardStore/getAvailableAssignmentsDashboard', {items: 10, page: 1})
        ]).then(() => {
          this.availableAssignmentsProgress = false;
          this.bookedAssignmentsProgress = false;
        });
      },
      clearFeedbackLocalStorage() {
        localStorage.removeItem('jobCompletedByColleague');
        localStorage.removeItem('showedUp');
        localStorage.removeItem('jobCompleted');
        localStorage.removeItem('anyOtherProblem');
        localStorage.removeItem('ticketComment');
        localStorage.removeItem('agreeWithReportedDate');
        localStorage.removeItem('problemDescription');
        localStorage.removeItem('didTravelToAddress');
      },
      showCooperationAgreementModal() {
        if (!this.cooperationAgreementSigned && this.agreementDoc) {
          this.$store.commit('ModalStore/setModal', {
            component: 'cooperation-agreement-modal',
            width: 600,
            disableClose: !this.$isGodmode()
          });
        }
      },
    },
    beforeMount() {
      this.getJobs();
      if (this.$store.state.openAssignModal || this.$cookieManager.getCookie('openAssignModal')) {
        this.$store.commit('ModalStore/setModal', {
          component: 'open-assign-modal',
          data: {
            title: this.$gettext('Permission error')
          }
        });
        this.$store.commit('setOpenAssignModalState', false);
      }
      if (this.$store.getters['UserInfoStore/welcomeModal']) {
        this.$store.dispatch('UserInfoStore/sendWelcome').then(() => {
          this.$store.commit('ModalStore/setModal', {
            component: 'welcome-modal',
            data: {
              title: ''
            }
          });
        });
      }
      if (this.showDialectsModal) {
        this.$store.commit('ModalStore/setModal', {
          component: 'dialects-modal',
        });
      }
    },
    mounted() {
      this.showCooperationAgreementModal();
      this.fetchAvailableBookedAssignments();
      this.openPersonInfoModal();
      this.setClientWidth();
      window.addEventListener('resize', this.setClientWidth);
      this.clearFeedbackLocalStorage();
      this.intervalId = setInterval(() => {
        if (this.$moment().minute() % 30 === 0 && this.intervalCanUpdate) {
          this.updateDashboardAssignments();
        }
        if (this.$moment().minute() % 30 !== 0) {
          this.intervalCanUpdate = true;
        }
      }, 60000);
    },
    beforeDestroy() {
      clearInterval(this.intervalId);
      this.$store.commit('DashboardSearchStore/clearStore');
      this.$store.commit('s_DashboardStore/removeSDashboardData');
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('TTPrivateEventsStore/clearStore');
      next();
    }
  };
</script>

<style>
  .dashboard__calendar-wrapper .fc-timeGridDay-view .fc-time-grid .fc-slats td {
    height: 1.3em;
  }

  @media (min-width: 1170px) {
    .sView .dashboard__assignments-section {
      overflow: auto;
    }
  }

  @media (max-width: 1169px) and (min-width: 1024px) {
    .sView .dashboard__assignment-list .assignment__link:nth-child(3) ~ .assignment__link {
      display: none;
    }
  }

  @media (max-width: 1025px) and (min-width: 768px) {
    .sView .dashboard__assignment-list .assignment__link:nth-child(2) ~ .assignment__link {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .sView .dashboard__assignment-list .assignment__link:nth-child(5) ~ .assignment__link {
      display: none;
    }
  }
</style>

<style scoped>
  .dashboard {
    display: block;
    flex-grow: 1;
    width: 100%;
    padding: 15px;
    padding-bottom: 20px;
  }

  .dashboard__widgets-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .dashboard__assignment-sections-wrapper {
    display: flex;
    justify-content: space-between;
    width: 66.666666%;
  }

  .dashboard__profile-sections-cont,
  .dashboard__calendar-section-wrapper,
  .dashboard__assignments-sections-cont {
    display: block;
    width: calc(33.33333333% - 13px);
  }

  .dashboard__assignments-sections-cont {
    width: calc(50% - 7px);
  }

  .dashboard__calendar-section,
  .dashboard__profile-section,
  .dashboard__strength-section,
  .dashboard__graph-section,
  .dashboard__invitation-section,
  .dashboard__assignments-section,
  /* .dashboard__hot-availability-section, */
  .dashboard__stand-by-section,
  .dashboard__promotion-section,
  .dashboard__withdraw-section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 3px;
    background-color: #fff;
  }

  .dashboard__calendar-section:hover,
  .dashboard__profile-section:hover,
  .dashboard__strength-section:hover,
  .dashboard__graph-section:hover,
  .dashboard__invitation-section:hover,
  .dashboard__assignments-section:hover,
  /* .dashboard__hot-availability-section:hover, */
  .dashboard__stand-by-section:hover,
  .dashboard__withdraw-section:hover {
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  .dashboard__invitation-section {
    padding-bottom: 35px;
    background-color: #9dd;
  }

  .dashboard__profile-section {
    padding-bottom: 15px;
  }

  .dashboard__calendar-section {
    height: 816px;
  }

  .dashboard__profile-section,
  .dashboard__invitation-section {
    height: 175px;
  }

  .dashboard__strength-section,
  .dashboard__graph-section {
    height: 200px;
  }

  .dashboard__assignments-section {
    height: 100%;
  }

  .dashboard__profile-section,
  .dashboard__strength-section,
  .dashboard__graph-section {
    margin-bottom: 22px;
  }

  .dashboard__assignments-section:not(:last-child) {
    margin-bottom: 15px;
  }

  /* .dashboard__hot-availability-section--mobile {
    display: none;
  } */

  .dashboard__section-header {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .dashboard__calendar-wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
    padding-top: 3px;
  }

  .dashboard__switchers-cont {
    display: flex;
    align-items: center;
    width: auto;
    font-size: 18px;
  }

  .dashboard__switcher {
    display: inline-block;
    width: auto;
    color: #a7abbd;
    line-height: 20px;
  }

  .dashboard__switcher--active {
    color: #000;
  }

  .dashboard__switcher--active:hover {
    color: #a7abbd;
  }

  .dashboard__switcher:hover {
    color: #000;
  }

  .dashboard__switcher:focus {
    box-shadow: var(--default-focus-shadow);
  }

  .dashboard__switchers-devider {
    display: inline-block;
    width: 2px;
    height: 20px;
    margin: 0 10px;
    background-color: #a7abbd;
  }

  .dashboard__section-title {
    margin: 0;
    font-weight: normal;
    font-size: 18px;
  }

  .dashboard__section-text {
    display: block;
    width: 100%;
    font-size: 12px;
  }

  .dashboard__section-link {
    align-self: center;
    font-size: 12px;
    text-decoration: underline;
  }

  .dashboard__section-link:hover {
    color: #7a829d;
  }

  .dashboard__section-link:focus {
    box-shadow: var(--default-focus-shadow);
  }

  .dashboard__stand-by-section,
  .dashboard__promotion-section {
    margin-bottom: 10px;
  }

  @media (min-width: 1024px) and (max-width: 1169px) {
    .dashboard__profile-section {
      height: 200px;
    }

    .dashboard__calendar-section {
      height: 645px;
    }

    .dashboard__invitation-section {
      display: none;
    }

    .dashboard__strength-section {
      height: 200px;
    }

    .dashboard__graph-section {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .dashboard {
      padding: 15px 15px 20px;
    }

    .dashboard__assignment-sections-wrapper {
      flex-direction: column;
      width: calc(50% - 7px);
    }

    .dashboard__assignments-sections-cont,
    .dashboard__calendar-section-wrapper {
      width: calc(50% - 7px);
      margin-bottom: 15px;
    }

    .dashboard__assignments-sections-cont {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
    }

    .dashboard__assignments-section {
      flex-grow: 1;
      width: 100%;
    }

    .dashboard__assignments-sectioncont--confirmed {
      order: 1;
    }

    .dashboard__calendar-section {
      order: 2;
    }

    .dashboard__assignments-section-cont--available {
      order: 3;
    }

    .dashboard__assignments-section:not(:last-child) {
      margin-bottom: 12px;
    }

    .dashboard__profile-sections-cont {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .dashboard__profile-section,
    .dashboard__strength-section,
    .dashboard__graph-section,
    .dashboard__invitation-section {
      width: calc(50% - 7px);
    }

    .dashboard__profile-section {
      height: 200px;
    }

    .dashboard__graph-section {
      order: 2;
    }

    .dashboard__strength-section {
      order: 3;
      margin-bottom: 0;
    }

    .dashboard__invitation-section {
      display: flex;
      order: 4;
    }

    .dashboard__profile-section,
    .dashboard__strength-section {
      margin-right: 14px;
    }

    .dashboard__strength-section,
    .dashboard__invitation-section {
      height: 185px;
    }
  }

  @media (max-width: 767px) {
    .dashboard {
      padding: 15px 0 20px;
    }

    .dashboard__widgets-section {
      display: block;
    }

    .dashboard__assignment-sections-wrapper {
      display: block;
      width: 100%;
    }

    .dashboard__assignments-sections-cont,
    .dashboard__calendar-section-wrapper,
    .dashboard__profile-sections-cont {
      width: 100%;
    }

    .dashboard__calendar-section {
      height: 690px;
      padding: 15px;
    }

    .dashboard__assignments-sections-cont,
    .dashboard__calendar-section,
    .dashboard__stand-by-section {
      margin-bottom: 12px;
    }

    .dashboard__assignments-section,
    .dashboard__hot-availability-section {
      height: initial;
      padding: 15px;
    }

    /* .dashboard__hot-availability-section {
      display: none;
    }

    .dashboard__hot-availability-section--mobile {
      display: flex;
    } */

    .dashboard__profile-section,
    .dashboard__strength-section,
    .dashboard__graph-section,
    .dashboard__invitation-section {
      height: initial;
      padding: 15px;
    }
  }
</style>
